<template>
  <div class="matchmakerForm">
    <div class="title">个人信息</div>
    <div class="box">
      <div class="item must">
        <div class="item-l">姓名</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.peopleName"></v-input>
        </div>
      </div>
      <div class="item must">
        <div class="item-l">性别</div>
        <div class="item-r">
          <v-radio-group
            :radioObj="sexTypeOps"
            :value.sync="postData.sex"
          ></v-radio-group>
        </div>
      </div>
      <div class="item must">
        <div class="item-l">虚岁</div>
        <div class="item-r">
          <v-stepper
            v-model="postData.nominalAge"
            :min="1"
            :max="200"
          ></v-stepper>
          <span class="danwei">岁</span>
        </div>
      </div>
      <div class="item must" @click="selectDate">
        <div class="item-l">出生日期</div>
        <div class="item-r select">
          {{ postData.birthDate ? postData.birthDate : "请选择" }}
        </div>
      </div>
      <div class="item must" @click="selectData(1)">
        <div class="item-l">属相</div>
        <div class="item-r select">
          {{
            postData.chineseZodiacSign ? postData.chineseZodiacSign : "请选择"
          }}
        </div>
      </div>
      <div class="item must" @click="selectData(2)">
        <div class="item-l">学历</div>
        <div class="item-r select">
          {{ postData.education ? postData.education : "请选择" }}
        </div>
      </div>
      <div class="item must">
        <div class="item-l">籍贯</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.nativePlace"
          ></v-input>
        </div>
      </div>
      <div class="item must" @click="selectData(3)">
        <div class="item-l">婚史</div>
        <div class="item-r select">
          {{ postData.maritalHistory ? postData.maritalHistory : "请选择" }}
        </div>
      </div>
      <div class="item">
        <div class="item-l">身高</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.stature"></v-input
          ><span class="danwei">厘米</span>
        </div>
      </div>
      <div class="item">
        <div class="item-l">体重</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.weight"></v-input>
          <span class="danwei">公斤</span>
        </div>
      </div>
      <div class="item te must">
        <div class="item-l">照片</div>
        <div class="item-r">
          <v-upload
            class="oItem-b_upload"
            :imgUrl.sync="postData.picture"
            :activityPicture.sync="postData.picture"
            :maxCount="1"
            ref="load"
          ></v-upload>
        </div>
      </div>
      <div class="item">
        <div class="item-l">毕业院校</div>
        <div class="item-r">
          <v-input
            v-model="postData.graduateInstitutions"
            placeholder="请输入"
          ></v-input>
        </div>
      </div>
      <div class="item" @click="selectData(4)">
        <div class="item-l">学制</div>
        <div class="item-r select">
          {{ postData.schoolSystem ? postData.schoolSystem : "请选择" }}
        </div>
      </div>
      <div class="item">
        <div class="item-l">身份证号</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.idCard"></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">工作单位</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.company"></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">单位性质</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.companyType"
          ></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">具体岗位</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.station"></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">单位地址</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.companyAddress"
          ></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">每周休</div>
        <div class="item-r">
          <v-stepper
            v-model="postData.weekRestDays"
            :min="1"
            :max="7"
          ></v-stepper
          >天
        </div>
      </div>
      <div class="item">
        <div class="item-l">年收入</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.yearIncome"></v-input
          ><span class="danwei">万元</span>
        </div>
      </div>
      <div class="item">
        <div class="item-l">家庭地址</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.houseAddress"
          ></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">房屋面积</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.houseArea"></v-input>
          <span class="danwei">平方</span>
        </div>
      </div>
      <div class="item">
        <div class="item-l">另有其他房子</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.otherHouse"></v-input>
        </div>
      </div>
      <div class="item" @click="selectData(5)">
        <div class="item-l">产权所有</div>
        <div class="item-r select">
          {{ postData.equityOwnership ? postData.equityOwnership : "请选择" }}
        </div>
      </div>
      <div class="item">
        <div class="item-l">产权状态</div>
        <div class="item-r">
          <v-radio-group
            :radioObj="chanquanOps"
            :value.sync="postData.propertyStatus"
          ></v-radio-group>
        </div>
      </div>
      <div class="item">
        <div class="item-l">父亲工作单位</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.fatherCompany"
          ></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">母亲工作单位</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.motherCompany"
          ></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">是否独生</div>
        <div class="item-r">
          <v-radio-group
            :radioObj="dushengOps"
            :value.sync="postData.isOnlyChild"
          ></v-radio-group>
        </div>
      </div>
      <div class="item">
        <div class="item-l">兄弟姐妹概况</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.brotherAndSister"
          ></v-input>
        </div>
      </div>
      <div class="item" @click="selectData(6)">
        <div class="item-l">个人特长</div>
        <div class="item-r select">
          {{
            postData.characterSpecialty ? postData.characterSpecialty : "请选择"
          }}
        </div>
      </div>
      <div class="item">
        <div class="item-l">烟酒情况</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.tobaccoAndWine"
          ></v-input>
        </div>
      </div>
      <div class="secTitle">择偶要求</div>
      <div class="item">
        <div class="item-l">对方</div>
        <div class="item-r">
          <div
            @click="selectData(7)"
            :class="[
              'bigSmall',
              { select: postData.anotherBigSmall ? false : true },
            ]"
          >
            {{ postData.anotherBigSmall ? postData.anotherBigSmall : "请选择" }}
          </div>
          <div class="num">
            <v-stepper
              v-model="postData.bigSmallYear"
              :min="1"
              :max="200"
            ></v-stepper>
          </div>
          <span class="danwei">岁</span>
        </div>
      </div>
      <div class="item">
        <div class="item-l">属相无忌</div>
        <div class="item-r">
          <v-radio-group
            :radioObj="shuxiangOps"
            :value.sync="postData.chineseZodiacSignCondition"
            @change="change"
          ></v-radio-group>
        </div>
      </div>
      <div
        class="item"
        v-if="postData.chineseZodiacSignCondition == '有'"
        @click="selectData(8)"
      >
        <div class="item-l">最佳配</div>
        <div class="item-r select">
          {{ postData.bestMatch ? postData.bestMatch : "请选择" }}
        </div>
      </div>
      <div
        class="item"
        v-if="postData.chineseZodiacSignCondition == '有'"
        @click="selectData(9)"
      >
        <div class="item-l">忌配</div>
        <div class="item-r select">
          {{ postData.worstMatch ? postData.worstMatch : "请选择" }}
        </div>
      </div>
      <div class="item">
        <div class="item-l">外貌要求</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.appearanceRequirement"
          ></v-input>
        </div>
      </div>
      <div class="item" @click="selectData(10)">
        <div class="item-l">择偶区域</div>
        <div class="item-r select">
          {{ postData.anotherArea ? postData.anotherArea : "请选择" }}
        </div>
      </div>
      <div class="item" @click="selectData(11)">
        <div class="item-l">学历要求</div>
        <div class="item-r select">
          {{ postData.anotherEducation ? postData.anotherEducation : "请选择" }}
          <span class="danwei">以上</span>
        </div>
      </div>
      <div class="item">
        <div class="item-l">职业要求</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.anotherJob"></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">年收入</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.anotherYearIncome"
          ></v-input>
          <span class="danwei">万元</span>
        </div>
      </div>
      <div class="item">
        <div class="item-l">不喜欢什么职业</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.noJob"></v-input>
        </div>
      </div>
      <div class="item" @click="selectData(12)">
        <div class="item-l">要求</div>
        <div class="item-r select">
          {{
            postData.specificRequirement
              ? postData.specificRequirement
              : "请选择"
          }}
        </div>
      </div>
      <div class="item">
        <div class="item-l">QQ号码</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.qqNumber"></v-input>
        </div>
      </div>
      <div class="item must">
        <div class="item-l">联系号码(或手机)</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.mobile"></v-input>
        </div>
      </div>
      <div class="item">
        <div class="item-l">微信号</div>
        <div class="item-r">
          <v-input placeholder="请输入" v-model="postData.wxNumber"></v-input>
        </div>
      </div>
      <div class="item must">
        <div class="item-l">联系人</div>
        <div class="item-r">
          <v-input
            placeholder="请输入"
            v-model="postData.contactPerson"
          ></v-input>
        </div>
      </div>
      <div
        class="btn"
        @click="submit"
        v-if="form == '' || form.auditStatus == 99"
      >
        提交
      </div>
      <div
        class="btn2"
        @click="submit"
        v-if="form != '' && form.auditStatus != 99"
      >
        您已提交，点击提交修改
      </div>
      <div
        class="btn1"
        @click="submitBack"
        v-if="form != '' && form.auditStatus != 99"
      >
        撤回登记信息
      </div>
    </div>

    <v-picker
      :columns="columns"
      :valueShow.sync="showPicker"
      :valueKey="'text'"
      @clickOverlay="clickOverlay"
      @confirmPicker="confirmPick"
      @cancel="cancel"
    ></v-picker>
    <v-dateTimePicker
      type="date"
      :value="postData.birthDate"
      :valueShow.sync="isShowNurseryDate"
      :isAuto="true"
      @confirmPicker="confirmNurseryDate"
    ></v-dateTimePicker>
  </div>
</template>

<script>
var moment = require("moment");
import {
  getDsfContentInfoListUrl,
  withdrawalUrl,
  saveOrUpdateDsfRegisterInfoUrl,
  getDsfRegisterInfoByUserIdUrl,
} from "./api";
import {
  animalsType,
  educationType,
  chanQuanType,
  hunType,
  xueZhiType,
  teChangType,
  bigSmallType,
  areaType,
  askType,
} from "./map";
export default {
  name: "matchmakerForm",
  data() {
    return {
      isShowNurseryDate: false,
      columns: [],
      sexTypeOps: [
        { name: "男", value: "男" },
        { name: "女", value: "女" },
      ],
      chanquanOps: [
        { name: "全款", value: "全款" },
        { name: "按揭", value: "按揭" },
      ],
      shuxiangOps: [
        { name: "无", value: "无" },
        { name: "有", value: "有" },
      ],
      dushengOps: [
        { name: "是", value: "是" },
        { name: "否", value: "否" },
      ],
      showPicker: false,
      type: "",
      form: "",
      postData: {
        peopleName: "",
        sex: "男",
        nominalAge: 1,
        birthDate: "",
        chineseZodiacSign: "",
        education: "",
        nativePlace: "",
        maritalHistory: "",
        stature: "",
        weight: "",
        picture: "",
        graduateInstitutions: "",
        schoolSystem: "",
        idCard: "",
        company: "",
        companyType: "",
        station: "",
        companyAddress: "",
        weekRestDays: 1,
        yearIncome: "",
        houseAddress: "",
        houseArea: "",
        otherHouse: "",
        equityOwnership: "",
        propertyStatus: "全款",
        fatherCompany: "",
        motherCompany: "",
        isOnlyChild: "是",
        brotherAndSister: "",
        tobaccoAndWine: "",
        characterSpecialty: "",
        chineseZodiacSignCondition: "无",
        bigSmallYear: 1,
        anotherBigSmall: "",
        bestMatch: "",
        worstMatch: "",
        appearanceRequirement: "",
        anotherEducation: "",
        anotherArea: "",
        anotherJob: "",
        anotherYearIncome: "",
        noJob: "",
        specificRequirement: "",
        qqNumber: "",
        mobile: "",
        wxNumber: "",
        contactPerson: "",
        id: "",
      },
      selectObj: {
        1: {
          key: "chineseZodiacSign",
          value: animalsType,
        },
        2: {
          key: "education",
          value: educationType,
        },
        3: {
          key: "maritalHistory",
          value: hunType,
        },
        4: {
          key: "schoolSystem",
          value: xueZhiType,
        },
        5: {
          key: "equityOwnership",
          value: chanQuanType,
        },
        6: {
          key: "characterSpecialty",
          value: teChangType,
        },
        7: {
          key: "anotherBigSmall",
          value: bigSmallType,
        },
        8: {
          key: "bestMatch",
          value: animalsType,
        },
        9: {
          key: "worstMatch",
          value: animalsType,
        },
        10: {
          key: "anotherArea",
          value: areaType,
        },
        11: {
          key: "anotherEducation",
          value: educationType,
        },
        12: {
          key: "specificRequirement",
          value: askType,
        },
      },
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {
    this.getDsfRegisterInfo();
  },
  methods: {
    async submitBack() {
      let params = {
        id: this.form.id,
      };
      let res = await this.$axios.post(`${withdrawalUrl}?id=${this.form.id}`);
      if (res.code === 200) {
        this.$toast("撤回成功");
        this.getDsfRegisterInfo();
      } else {
      }
    },
    change(value) {
      this.postData.bestMatch = "";
      this.postData.worstMatch = "";
    },
    async submit() {
      if (!this.postData.peopleName) {
        this.$toast("请填写姓名");
        return;
      }
      if (!this.postData.birthDate) {
        this.$toast("请选择出生日期");
        return;
      }
      if (!this.postData.chineseZodiacSign) {
        this.$toast("请选择属相");
        return;
      }
      if (!this.postData.education) {
        this.$toast("请选择学历");
        return;
      }
      if (!this.postData.nativePlace) {
        this.$toast("请填写籍贯");
        return;
      }
      if (!this.postData.maritalHistory) {
        this.$toast("请选择婚史");
        return;
      }
      if (!this.postData.maritalHistory) {
        this.$toast("请选择婚史");
        return;
      }
      if (!this.postData.picture) {
        this.$toast("请上传照片");
        return;
      }
      if (!this.postData.mobile) {
        this.$toast("请填写联系号码");
        return;
      }
      if (!this.postData.contactPerson) {
        this.$toast("请填写联系人");
        return;
      }
      let params = {
        createUser: this.userId,
        tenantId: this.tenantId,
      };
      if (this.form.auditStatus == 99) {
        params.auditStatus = 0;
      }
      Object.assign(params, this.postData);
      let res = await this.$axios.post(
        `${saveOrUpdateDsfRegisterInfoUrl}`,
        params
      );
      if (res.code === 200) {
        this.$toast("提交登记成功");
        this.getDsfRegisterInfo();
      } else {
      }
    },
    confirmNurseryDate(value) {
      this.postData.birthDate = moment(value).format("YYYY-MM-DD");
      this.isShowNurseryDate = false;
    },
    selectDate() {
      this.isShowNurseryDate = true;
    },
    toArr(arr) {
      return arr ? JSON.parse(JSON.stringify(arr)) : "";
    },
    cancel() {
      this.columns = [];
      this.type = "";
      this.showPicker = false;
    },
    clickOverlay() {
      this.columns = [];
      this.type = "";
      this.showPicker = false;
    },
    selectData(data) {
      this.columns = this.toArr(this.selectObj[data]["value"]);
      this.type = data;
      this.showPicker = true;
    },
    confirmPick(data) {
      for (var i in this.selectObj) {
        if (this.type == i) {
          let key = this.selectObj[this.type]["key"];
          this.postData[key] = data.value;
        }
      }
      this.showPicker = false;
    },
    async getDsfRegisterInfo() {
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getDsfRegisterInfoByUserIdUrl}`, {
        params: params,
      });
      if (res.code === 200) {
        if (res.msg != "暂无承载数据") {
          this.form = res.data;
          for (var i in this.postData) {
            this.postData[i] = this.form[i];
          }
        }
      } else {
      }
    },
  },
};
</script>

<style lang="less" scoped>
.matchmakerForm {
  min-height: 100vh;
  padding: 1px 28px 88px 30px;
  .title {
    padding: 30px 0 30px 34px;
    font-weight: 700;
    font-size: 32px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 44px;
  }
  .btn,
  .btn1,
  .btn2 {
    width: 476px;
    height: 64px;
    background: linear-gradient(180deg, #fb9e89 0%, #f66551 100%);
    border-radius: 10px;
    font-weight: 700;
    font-size: 30px;
    color: #ffffff;
    line-height: 64px;
    text-align: center;
    margin: 40px auto 0;
  }
  .btn1 {
    background: linear-gradient(180deg, #ff938b 0%, #ed3232 100%);
  }
  .btn2 {
    background: linear-gradient(180deg, #ffbf54 0%, #fd9f2c 100%);
  }
  .box {
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(218, 216, 216, 0.5);
    border-radius: 16px;
    padding: 0 30px 42px;
    .secTitle {
      margin: 40px 0;
      font-weight: 700;
      font-size: 32px;
      color: rgba(0, 0, 0, 0.85);
      line-height: 44px;
    }
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 30px 0;
      border-bottom: 2px solid rgba(0, 0, 0, 0.04);
      position: relative;
      .item-l {
        width: 236px;
        font-weight: 400;
        font-size: 30px;
        color: #333333;
        line-height: 42px;
      }
      .item-r {
        flex: 1;
        font-weight: 400;
        font-size: 26px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 36px;
        display: flex;
        align-items: center;
        justify-content: right;
        .danwei {
          width: 60px;
          margin-left: 10px;
        }
        .bigSmall {
          margin-right: 20px;
        }
        /deep/ .van-stepper {
          margin-right: 10px;
        }
        /deep/ .van-cell {
          padding: 0;
          .van-field__control {
            text-align: right;
          }
        }
      }
    }
    .must {
      position: relative;
      &::after {
        position: absolute;
        height: 24px;
        content: "*";
        top: 0;
        bottom: 0;
        left: -20px;
        color: red;
        margin: auto;
      }
    }
    .te {
      display: block;
      .item-r {
        justify-content: left;
        margin-top: 20px;
      }
    }
    .select {
      position: relative;
      padding-right: 28px;
      &::after {
        position: absolute;
        content: "";
        width: 10px;
        height: 16px;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        background: url("./img/arrowForm.png") no-repeat;
        background-size: 100% 100%;
      }
    }
  }
}
</style>
